@import "../variables/colors.scss";

header {
  position: fixed;
  width: 100%;
  z-index: 2000;

  .header_container, .header_container .intermediate {
    height: 100%;
    width: 100%;

    i {
      color: white;
    }
  }

  .header_top.row {
    height: 46px;
    background-color: rgb(26, 26, 26);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .header_container > div, .header_container > nav {
    padding-left: 0;
    padding-right: 0;
  }

  .header_top {
    ul.social_links {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      list-style: none;
      margin-bottom: 0;
      padding: 0 !important;
      height: 100%;
    }
  }

  .header_top ul.social_links li {
    margin-right: 12px;
    margin-bottom: 0 !important;
  }

  .header_top {
    .connect {
      display: -webkit-box;

      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 100%;
    }
  }

  .header_nav {
    background-color: #333333;
  }

  .login, .myprofile, .members_all, .groups_all, .connected {
    background-color: rgb(28, 212, 176);
  }

  .signin, .login, .myprofile, .members_all, .groups_all, .search, .connected, .header-button {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 222px;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
  }

  .connected {
    width: auto;
    color: white;
    padding: 0 15px;
  }

  .search, .header-button {
    min-width: 46px;
    width: auto !important;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  .header-button {
    padding: 0 15px;
    margin-right: 20px;

    a {
      color: rgb(255, 255, 255);
      font-size: 14px;
      font-weight: 400;
      text-decoration: none;
    }
  }

  .signin, .login, .myprofile, .members_all, .groups_all {
    a {
      color: rgb(255, 255, 255);
      font-size: 16px;
      font-weight: 400;
      text-decoration: none;
    }
  }

  nav {
    ul.navbar-nav {
      padding: 0;
      align-items: center;
      align-content: center;

      li {
        margin-bottom: 0;
        margin-left: 10px;
      }

      a {
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;

        &.dropdown-item {
          padding: 12px 30px;
          color: $turquoise;
        }

        &:hover {
          color: $mint;
        }
      }
    }
  }

  a {
    color: #444;
    text-decoration: none;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
  }

  .logo {
    align-self: center;

    img {
      height: 40px;
      margin-top: -4px;
    }
  }
}


@media only screen and (min-width: 1140px) {
  .header_top.row {
    height: 34px;
  }
}

@media only screen and (min-width: 1200px) {
  .header_nav.row {
    background-color: rgb(51, 51, 51);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media only screen and (min-width: 768px) {
  .mobile-nav {
    display: none
  }
}

@media only screen and (min-width: 1140px) {
  nav ul a, .footer_nav ul a, .footer_cat ul a {
    font-size: 14px !important;
  }
}

@media only screen and (min-width: 1140px) {
  .header_nav.row {
    height: 64px !important;
  }
}

@media only screen and (min-width: 320px) {
  .header_nav.row {
    min-height: 56px !important;
  }

  .header_container-logo {
    height: 64px !important;
  }

}

@media only screen and (max-width: 768px) {
  .header_nav.row {
    height: 66px !important;

    .logo {
      img {
        width: 100%;
      }
    }
  }

  header .header_nav {
    background: $dark;
  }

  .nav-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 10px;
  }

  .nav-responsive .logo img {
    height: auto;
  }

  .nav-responsive-collapse {
    background: $grey;
    overflow: scroll;
    max-height: 100vh;

    ul {

      display: flex;
      justify-content: center;
      align-items: center;

      li {
        margin-top: 16px;
        margin-bottom: 16px;
        width: 60%;
        text-align: left;
      }

      a, p {
        color: white;
      }
    }

    #collapseExample, #collapseExample2 {
      a {
        color: $intermediate-grey;
        display: block;
        width: 100%;
        padding: .25rem 1.5rem;
        font-weight: 400;
        text-align: inherit;
        white-space: nowrap;
        border: 0;
      }
    }

  }
}

@media only screen and (min-width: 1200px) {
  .header_nav.row {
    background-color: rgb(51, 51, 51);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media only screen and (min-width: 1200px) {
  .header_container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}
